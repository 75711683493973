// TODO: Move this query to the AncilTable component, here for now due to testing limitations
export const ANCIL_TABLE_QUERY = gql`
  query AncillaryTableQuery($hasConsent: Boolean, $onlyActive: Boolean) {
    ancillaryLeads(hasConsent: $hasConsent, onlyActive: $onlyActive) {
      id
      leadStatus
      leadCompany
      triggerType
      triggeredByUser {
        id
        name
      }
      extPrimaryAgentName
      extSecondaryAgentName
      decryptedName
      propertyAddress
      createdAt
      triggeredAt
      processedAt
      formattedDate
      hasConsent
    }
  }
`
