import React, { FC, useCallback, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  LinkIcon,
  ShieldExclamationIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { isNil } from 'ramda'

import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton'
import { UserCard } from 'src/components/Library/UserHoverCard/UserHoverCard'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useOs } from 'src/lib/hooks/UseOS'

import {
  SentimentAnalysisMetricName,
  SentimentAnalysisUserBarProps,
} from '../SentimentAnalysisHelper'
import { validateTokenExpiry } from '../SentimentAnalysisUtils'

import SentimentAnalysisCSIScore from './SentimentAnalysisCSIScore'

const SentimentAnalysisUserBar: FC<SentimentAnalysisUserBarProps> = ({
  userProfile,
  isAnalysing,
  isUnlinking,
  refreshTokenExpiresOn,
  userIsAuthenticated,
  noSavedToken,
  generateAzureCode,
  analyseLandlordEmails,
  azureConnected,
  unlinkEmailAccount,
  sentimentAnalysisPermission,
}) => {
  const confirmReAuthentication = useConfirm()
  const { trackEvent } = useAnalytics()
  const apolloClient = useApolloClient()
  const os = useOs()

  const [refreshing, setRefreshing] = useState(false)
  const [fetchCompleted, setFetchCompleted] = useState(false)
  const [isReAuthenticating, setIsReAuthenticating] = useState(false)
  const lastRunDateString = sentimentAnalysisPermission?.lastSuccessfulRun
  const lastSuccessfulRun = lastRunDateString ? dayjs(lastRunDateString) : null

  useEffect(() => {
    const lastManualJobCompleted =
      sentimentAnalysisPermission?.lastManualJobCompleted
    if (!isNil(lastManualJobCompleted)) {
      setFetchCompleted(lastManualJobCompleted)
    }
  }, [sentimentAnalysisPermission.lastManualJobCompleted, refreshing])

  const handleRefresh = useCallback(
    async (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setFetchCompleted(false)
      setRefreshing(true)
      await apolloClient.refetchQueries({
        include: [
          'FindSentimentAnalysisQuery',
          'FindSentimentAnalysisPermissionQuery',
        ],
      })
      setRefreshing(false)
    },
    [apolloClient],
  )

  useEffect(() => {
    if (!noSavedToken) {
      setIsReAuthenticating(false)
    }
  }, [noSavedToken])

  const handleUnlinkEmailAccount = useCallback(
    async (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      await confirmReAuthentication({
        title: 'Are you sure you want to unlink your email?',
        description: (
          <>
            Email Sentiment will no longer have authorisation to access your
            emails for analysing.
            <br />
            <br />
            You will need to reauthenticate to use this feature again.
          </>
        ),
        confirmationText: 'Unlink',
      })
        .then(() => {
          // Fire and forget - unlinkEmailAccount has its own handlers
          unlinkEmailAccount()
        })
        .catch(() => {
          // swallow cancel button
        })

      trackEvent('Sentiment Analysis', 'click unlink email account button')
    },
    [confirmReAuthentication, trackEvent, unlinkEmailAccount],
  )

  const analyseEmails = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // We have to revalidate on click (for times when browser is left open)
      const refreshTokenIsValid =
        refreshTokenExpiresOn !== null
          ? validateTokenExpiry(refreshTokenExpiresOn)
          : false

      if (!userIsAuthenticated || !refreshTokenIsValid) {
        await confirmReAuthentication({
          title: 'Your email authentication token has expired.',
          description: 'Please reauthenticate your account.',
          confirmationText: 'Reauthenticate',
        })
          .then(() => {
            // Fire and forget - generateAzureCode has its own handlers
            generateAzureCode(true)
          })
          .catch(() => {
            // swallow cancel button
          })
      } else {
        const ctrlKey = !!event?.ctrlKey
        const shiftKey = !!event?.shiftKey
        const metaKey = !!event?.metaKey

        const addNewContacts = (() => {
          if (os === 'macos') {
            return metaKey && shiftKey
          } else {
            return ctrlKey && shiftKey
          }
        })()
        // Fire and forget - analyseLandlordEmails has its own handlers
        analyseLandlordEmails({
          addNewContacts,
        })
        setFetchCompleted(false)
      }

      trackEvent('Sentiment Analysis', 'click analyze emails button')
    },
    [
      analyseLandlordEmails,
      confirmReAuthentication,
      generateAzureCode,
      trackEvent,
      refreshTokenExpiresOn,
      userIsAuthenticated,
    ],
  )

  return (
    <div className="flex flex-wrap items-end justify-between border-b border-gray-200 pb-6">
      <div className="flex items-center gap-4">
        <UserCard
          user={userProfile}
          elevation={0}
          sx={{ '& .MuiCardHeader-root': { padding: '0!important' } }}
        />
        {azureConnected && (
          <Tooltip title="Unlink email account">
            <div>
              <IconButton
                size={'medium'}
                aria-busy={isUnlinking}
                disabled={isUnlinking}
                className={'bg-gray-100 hover:!bg-gray-200'}
                onClick={handleUnlinkEmailAccount}
              >
                {isUnlinking ? (
                  <ArrowPathIcon className="h-5 w-5 animate-spin" />
                ) : (
                  <LinkIcon className={'h-5 w-5'} />
                )}
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
      <div className="flex grow items-end justify-end gap-10">
        {/* Commenting out below for now until decision made on how the overall score will be shown RD */}
        {/* <div className="flex items-center gap-2">
          <SentimentAnalysisCSIScore
            overallScore={userProfile.overallScore}
            statusCounts={userProfile.landlordStatusCount}
            tooltip={`${SentimentAnalysisMetricName.esi} over all time`}
          />

          <Tooltip
            title="Overall ESI scores are calculated based on the sentiment of emails received from your contacts."
            placement="top"
            arrow
          >
            <QuestionMarkCircleIcon className="h-5 w-5" />
          </Tooltip>
        </div> */}
        <div className="flex items-center justify-end gap-2">
          {noSavedToken ? (
            <Tooltip title="Authenticate your email account">
              <div>
                <Button
                  color={'warning'}
                  className={'items-center hover:bg-yellow-500'}
                  startIcon={
                    <ShieldExclamationIcon className={'h-5 w-5 stroke-2'} />
                  }
                  loading={isReAuthenticating}
                  onClick={() => {
                    generateAzureCode()
                    setIsReAuthenticating(true)
                  }}
                >
                  Authenticate
                </Button>
              </div>
            </Tooltip>
          ) : (
            <>
              <Button
                fullWidth={false}
                variant="outlined"
                endIcon={
                  <ArrowPathIcon
                    className={`h-5 w-5 ${refreshing ? 'animate-spin' : ''}`}
                  />
                }
                onClick={handleRefresh}
              >
                Refresh
              </Button>
              <Tooltip
                title={`Last Fetched: ${
                  lastSuccessfulRun?.format('DD/MM/YY h:mm:ssa') ?? 'never'
                }`}
              >
                <div>
                  <Button
                    fullWidth={false}
                    aria-busy={isAnalysing}
                    disabled={isAnalysing || noSavedToken || !fetchCompleted}
                    endIcon={
                      isAnalysing ? (
                        <ArrowPathIcon className="h-5 w-5 animate-spin" />
                      ) : (
                        <ArrowDownTrayIcon className={'h-5 w-5'} />
                      )
                    }
                    onClick={analyseEmails}
                    buttonDataTestId="sentiment-analysis-analyse-emails-button"
                  >
                    Fetch
                  </Button>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SentimentAnalysisUserBar
