import { useEffect, useMemo, useState } from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'
import {
  getAzureTokenCountByClient,
  getAzureTokenCountByClientVariables,
} from 'types/graphql'

import { routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import FilterNavTabs, {
  NavTabs,
} from 'src/components/FilterNavTabs/FilterNavTabs'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import FlaggedSentimentEmailsByMemberCell from 'src/components/Settings/SentimentAnalysis/FlaggedSentimentEmailsByMemberCell'
import FlaggedSentimentEmailsHistoryByMemberCell from 'src/components/Settings/SentimentAnalysis/FlaggedSentimentEmailsHistoryByMemberCell'
import SettingsSentimentAnalysisCell from 'src/components/Settings/SentimentAnalysis/SettingsSentimentAnalysisCell'
import useQuota from 'src/lib/hooks/Quota/useQuota'
import { SENTIMENT_ADMIN_TABS } from 'src/pages/SettingsSentimentAnalysisPage/types'

import { AZURE_TOKEN_COUNT_BY_CLIENT } from '../SettingsSentimentAnalysisQueries'

import SettingsSentimentAnalysisFilterBar from './SettingsSentimentAnalysisFilterBar'
import SettingsSentimentAnalysisRequestAuth from './SettingsSentimentAnalysisRequestAuth'

const navigationOptions: NavTabs[] = [
  { key: SENTIMENT_ADMIN_TABS.OVERVIEW, title: 'Email Sentiment' },
  { key: SENTIMENT_ADMIN_TABS.MEMBERS, title: 'Member Management' },
  { key: SENTIMENT_ADMIN_TABS.FLAGGED, title: 'Flagged Emails' },
  { key: SENTIMENT_ADMIN_TABS.FLAGGEDHISTORY, title: 'Flagged Emails History' },
]

const SettingsSentimentAnalysis = (props: { tab: SENTIMENT_ADMIN_TABS }) => {
  const [queryLoading, setQueryLoading] = useState<boolean>(true)
  const [clientAuthenticated, setClientAuthenticated] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState(props.tab)
  const selectedTabIndex = useMemo(() => {
    return navigationOptions.findIndex((tab) => tab.key === activeTab)
  }, [activeTab])

  const handleTabClick = (selected: SENTIMENT_ADMIN_TABS) => {
    const urlPath = routes.settingsSentimentAnalysisTab({
      tab: selected.toLowerCase(),
    })
    window.history.pushState({}, '', urlPath)
    setActiveTab(selected)
  }

  // navigate to the tab if url changes (notification is clicked)
  useEffect(() => {
    setActiveTab(props.tab)
  }, [props.tab])

  useQuery<getAzureTokenCountByClient, getAzureTokenCountByClientVariables>(
    AZURE_TOKEN_COUNT_BY_CLIENT,
    {
      onCompleted: (data) => {
        setQueryLoading(false)

        if (data?.azureTokenCountByClient > 0) {
          setClientAuthenticated(true)
        }
      },
    },
  )

  const { QuotaComponent, reloadQuota } = useQuota({
    feature: FLAG_FEATURES.SENTIMENT_ANALYSIS,
  })

  return (
    <div>
      {queryLoading && (
        <div className="flex min-h-[calc(100vh-14rem)] flex-col items-center justify-center">
          <LoadingSpinner />
        </div>
      )}

      {!queryLoading && !clientAuthenticated && (
        <SettingsSentimentAnalysisRequestAuth
          setClientAuthenticated={setClientAuthenticated}
        />
      )}

      {!queryLoading && clientAuthenticated && (
        <>
          <div className="flex flex-wrap items-end justify-between border-b">
            <div>
              <FilterNavTabs
                navTabs={navigationOptions}
                selectedTab={selectedTabIndex}
                onTabClickCallBack={handleTabClick}
                GATracker={{
                  category: 'Sentiment Analysis',
                }}
              />
            </div>
            <div
              onClick={() => reloadQuota()}
              onKeyDown={() => reloadQuota()}
              role="button"
              tabIndex={0}
              className={'w-1/3 py-2'}
            >
              <QuotaComponent />
            </div>
          </div>
          {activeTab === SENTIMENT_ADMIN_TABS.OVERVIEW && (
            <div className="pt-4">
              <SettingsSentimentAnalysisFilterBar />
            </div>
          )}
          {activeTab === SENTIMENT_ADMIN_TABS.MEMBERS && (
            <SettingsSentimentAnalysisCell />
          )}
          {activeTab === SENTIMENT_ADMIN_TABS.FLAGGED && (
            <FlaggedSentimentEmailsByMemberCell />
          )}
          {activeTab === SENTIMENT_ADMIN_TABS.FLAGGEDHISTORY && (
            <FlaggedSentimentEmailsHistoryByMemberCell />
          )}
        </>
      )}
    </div>
  )
}

export default SettingsSentimentAnalysis
