import React from 'react'

import { Chip, ChipProps } from '@mui/material'
import { LeadStatus } from 'types/graphql'

interface Props extends ChipProps {
  status: LeadStatus
}

export default function LeadStatusChip({
  status,
  className: additionalClassNames,
  ...rest
}: Props): React.ReactElement {
  const statusClassNames: Record<LeadStatus, string> = {
    ['NEW']: 'bg-indigo-100 text-indigo-600',
    ['PENDING']: 'bg-blue-200 text-blue-700',
    ['WITH_REFERRER']: 'bg-blue-200 text-blue-700',
    ['CLOSED_WON']: 'bg-green-100 text-green-600',
    ['CLOSED_LOST']: 'bg-red-100 text-red-600',
    ['CANCELLED']: 'bg-red-100 text-red-600',
    ['BAD_DATA']: 'bg-orange-100 text-orange-600',
  }

  return (
    <Chip
      size={'small'}
      label={status.replaceAll('_', ' ').toLowerCase()}
      className={`rounded-xl px-1 py-2 capitalize ${statusClassNames[status] || 'bg-gray-300 text-black'} ${additionalClassNames}`}
      {...rest}
    />
  )
}
