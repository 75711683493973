import {
  useEffect,
  useState,
  type ChangeEvent,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { IconoirProvider } from 'iconoir-react'

import Switch from 'src/components/Library/Switch/Switch'

import {
  BaserowFieldType,
  BaserowFormulaType,
  BucketByValues,
} from '../../lib/enums'
import type {
  AnyBaserowField,
  BarChartAggregateType,
  BarChartOrder,
  BarChartSettings,
  BarChartSort,
  BarChartViewType,
  BaserowReference,
  HubDashChartSettings,
} from '../../lib/types'
import { getIconForField } from '../../RecordExpand/RecordExpand'
import type { CardFormErrors } from '../CardSettingsDrawer'
import {
  formulaNumberFields,
  numberTypeFields,
  summaryNiceName,
  SummaryType,
} from '../FieldSummarySettings'

interface BarChartDataSettingsProps {
  fieldsList: AnyBaserowField[]
  chartSettings: HubDashChartSettings
  setChartSettings: Dispatch<SetStateAction<HubDashChartSettings>>
  chartCategory: AnyBaserowField
  setChartCategory: Dispatch<SetStateAction<AnyBaserowField>>
  loadingViews: boolean
  selectedTable: BaserowReference
  formErrors: CardFormErrors
  setFormErrors: Dispatch<SetStateAction<CardFormErrors>>
}

type BucketOption = {
  name: string
  value: BucketByValues
}

export const isBucketField = (dataField: AnyBaserowField): boolean => {
  if (dataField) {
    // Allow Date Fields
    if (
      dataField?.type === BaserowFieldType.DATE ||
      dataField?.type === BaserowFieldType.CREATED_ON ||
      dataField?.type === BaserowFieldType.LAST_MODIFIED
    ) {
      return true
    }

    // Allow Lookup or Formula if return type matches
    if (
      dataField?.type === BaserowFieldType.LOOKUP ||
      dataField?.type === BaserowFieldType.FORMULA
    ) {
      // Allow Date returns
      if (
        dataField?.formula_type === BaserowFormulaType.DATE ||
        dataField?.formula_type === BaserowFormulaType.DATE_INTERVAL
      ) {
        return true
      }

      // Allow Date Array returns
      if (
        dataField?.array_formula_type === BaserowFormulaType.DATE ||
        dataField?.array_formula_type === BaserowFormulaType.DATE_INTERVAL
      ) {
        return true
      }
    }
  }

  return false
}

const BarChartDataSettings: FC<BarChartDataSettingsProps> = ({
  fieldsList,
  chartSettings,
  setChartSettings,
  chartCategory,
  setChartCategory,
  loadingViews,
  selectedTable,
  formErrors,
  setFormErrors,
}) => {
  const barChartSettings = chartSettings?.barChart as BarChartSettings

  // Switching between Horizontal/Vertical changes the chart structure
  const isHorizontal = barChartSettings?.layout?.orientation !== 'vertical'

  const [barChartViewType, setBarChartViewType] = useState<BarChartViewType>(
    barChartSettings?.viewAxis?.type ?? 'recordCount',
  )

  const [selectedAggregate, setSelectedAggregate] =
    useState<BarChartAggregateType>(
      barChartSettings?.viewAxis?.aggregate ?? 'count',
    )

  const [summaryField, setSummaryField] = useState<AnyBaserowField>(
    barChartSettings?.viewAxis?.field ?? null,
  )

  const [groupByField, setGroupByField] = useState<AnyBaserowField>(
    barChartSettings?.viewAxis?.groupBy ?? null,
  )

  const bucketByOptions: BucketOption[] = [
    { name: 'Day', value: BucketByValues.DAY },
    { name: 'Week', value: BucketByValues.WEEK },
    { name: 'Month', value: BucketByValues.MONTH },
    { name: 'Quarter', value: BucketByValues.QUARTER },
    { name: 'Year', value: BucketByValues.YEAR },
    { name: 'Day of Week', value: BucketByValues.DAY_OF_WEEK },
    { name: 'Month of Year', value: BucketByValues.MONTH_OF_YEAR },
  ]

  const startingOption =
    bucketByOptions?.find(
      (option) => option.value === barChartSettings?.sourceAxis?.bucketValuesBy,
    ) ?? null
  const [bucketByValue, setBucketByValue] =
    useState<BucketOption>(startingOption)

  const showNumberTypes = numberTypeFields.includes(summaryField?.type)
  const showNumberTypesForFormulaResult =
    formulaNumberFields.includes(summaryField?.formula_type) ||
    formulaNumberFields.includes(summaryField?.array_formula_type)

  const numberSummaryOptions = [
    SummaryType.SUM,
    SummaryType.AVERAGE,
    SummaryType.MIN,
    SummaryType.MAX,
    SummaryType.MEDIAN,
  ]

  useEffect(() => {
    setSelectedAggregate(barChartSettings?.viewAxis?.aggregate ?? 'count')
    setBarChartViewType(barChartSettings?.viewAxis?.type ?? 'recordCount')
    setGroupByField(barChartSettings?.viewAxis?.groupBy ?? null)
    setSummaryField(barChartSettings?.viewAxis?.field ?? null)
  }, [barChartSettings])

  return (
    <>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <p className="text-lg font-bold">
            {isHorizontal ? 'X Axis' : 'Y Axis'}
          </p>
          <FormControl fullWidth error={selectedTable && !!formErrors.category}>
            <Autocomplete
              options={fieldsList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Category"
                  error={selectedTable && !!formErrors.category}
                />
              )}
              value={chartCategory}
              onChange={(_, newValue) => {
                if (newValue) {
                  setFormErrors({ ...formErrors, category: '' })
                }
                setChartSettings({
                  ...chartSettings,
                  category: newValue,
                })
                setChartCategory(newValue)
              }}
              loading={loadingViews}
              disabled={!selectedTable}
              renderOption={(props, option) => (
                <li {...props}>
                  <div className="flex items-center justify-between gap-4">
                    <IconoirProvider
                      iconProps={{
                        color: '#AAAAAA',
                        strokeWidth: 2,
                        width: '1em',
                        height: '1em',
                      }}
                    >
                      {getIconForField({ type: option?.type })}
                    </IconoirProvider>

                    <p>{option?.name}</p>
                  </div>
                </li>
              )}
            />
            <FormHelperText>
              {selectedTable && formErrors.category}
            </FormHelperText>
          </FormControl>
          <div className="flex flex-col gap-4">
            <FormControl>
              <InputLabel id="bar-sort-by">Sort By</InputLabel>
              <Select
                labelId="bar-sort-by"
                label="Sort By"
                value={barChartSettings?.sourceAxis?.sortBy || 'name'}
                onChange={(event) =>
                  setChartSettings({
                    ...chartSettings,
                    barChart: {
                      ...barChartSettings,
                      sourceAxis: {
                        ...barChartSettings?.sourceAxis,
                        sortBy: event.target.value as BarChartSort,
                      },
                    },
                  })
                }
              >
                <MenuItem value="name">Label</MenuItem>
                <MenuItem value="value">Value</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="bar-order">Order</InputLabel>
              <Select
                labelId="bar-order"
                label="Order"
                value={barChartSettings?.sourceAxis?.order || 'default'}
                onChange={(event) =>
                  setChartSettings({
                    ...chartSettings,
                    barChart: {
                      ...barChartSettings,
                      sourceAxis: {
                        ...barChartSettings?.sourceAxis,
                        order: event.target.value as BarChartOrder,
                      },
                    },
                  })
                }
              >
                <MenuItem value="default">Default Field Order</MenuItem>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  className="m-0 flex w-full justify-between"
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={barChartSettings?.viewAxis?.includeEmptyRecords}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setChartSettings({
                          ...chartSettings,
                          barChart: {
                            ...barChartSettings,
                            viewAxis: {
                              ...barChartSettings?.viewAxis,
                              includeEmptyRecords: event.target.checked,
                            },
                          },
                        })
                      }}
                    />
                  }
                  label="Include empty records"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
            {isBucketField(chartCategory) && (
              <Autocomplete
                options={bucketByOptions}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Bucket Dates By" />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option?.value === value?.value
                }}
                value={bucketByValue}
                onChange={(_, selectedOption) => {
                  setBucketByValue(selectedOption)
                  setChartSettings({
                    ...chartSettings,
                    barChart: {
                      ...barChartSettings,
                      sourceAxis: {
                        ...barChartSettings?.sourceAxis,
                        bucketValuesBy: selectedOption?.value ?? null,
                      },
                    },
                  })
                }}
                disabled={!chartCategory}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-lg font-bold">
            {isHorizontal ? 'Y Axis' : 'X Axis'}
          </p>
          <ToggleButtonGroup
            value={barChartViewType}
            exclusive
            onChange={(_, newValue) => {
              if (newValue !== null) {
                setBarChartViewType(newValue)
                setSelectedAggregate(null)
                setChartSettings({
                  ...chartSettings,
                  barChart: {
                    ...barChartSettings,
                    viewAxis: {
                      ...barChartSettings?.viewAxis,
                      type: newValue,
                      aggregate: 'count', // Reset aggregate when type changes so it doesn't die
                    },
                  },
                })
              }
            }}
            fullWidth
          >
            <ToggleButton
              value={'recordCount'}
              className="capitalize leading-6"
              disableRipple
            >
              Record Count
            </ToggleButton>
            <ToggleButton
              value={'fieldSummary'}
              className="capitalize leading-6"
              disableRipple
            >
              Field Summary
            </ToggleButton>
          </ToggleButtonGroup>

          {barChartViewType === 'fieldSummary' && (
            <FormControl fullWidth>
              <Autocomplete
                options={fieldsList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Field"
                    error={selectedTable && !!formErrors.category}
                  />
                )}
                value={summaryField}
                onChange={(_, newValue) => {
                  setSummaryField(newValue)
                  setSelectedAggregate(null)
                  setChartSettings({
                    ...chartSettings,
                    barChart: {
                      ...barChartSettings,
                      viewAxis: {
                        ...barChartSettings?.viewAxis,
                        field: newValue,
                        aggregate: null,
                      },
                    },
                  })
                }}
                loading={loadingViews}
                disabled={!selectedTable}
                renderOption={(props, option) => (
                  <li {...props}>
                    <div className="flex items-center justify-between gap-4">
                      <IconoirProvider
                        iconProps={{
                          color: '#AAAAAA',
                          strokeWidth: 2,
                          width: '1em',
                          height: '1em',
                        }}
                      >
                        {getIconForField({ type: option?.type })}
                      </IconoirProvider>

                      <p>{option?.name}</p>
                    </div>
                  </li>
                )}
              />
            </FormControl>
          )}

          <FormControl>
            <InputLabel id="bar-aggregate">Aggregate</InputLabel>
            <Select
              disabled={!chartCategory}
              labelId="bar-aggregate"
              label="Aggregate"
              value={selectedAggregate}
              onChange={(event) => {
                setSelectedAggregate(
                  event.target.value as BarChartAggregateType,
                )
                setChartSettings({
                  ...chartSettings,
                  barChart: {
                    ...barChartSettings,
                    viewAxis: {
                      ...barChartSettings?.viewAxis,
                      aggregate: event.target.value as BarChartAggregateType,
                    },
                  },
                })
              }}
            >
              <MenuItem value="count">Count</MenuItem>
              {barChartSettings?.viewAxis?.type === 'recordCount' && (
                <MenuItem value="distinct">Distinct</MenuItem>
              )}
              {barChartSettings?.viewAxis?.type === 'fieldSummary' &&
                numberSummaryOptions.map((option) => (
                  <MenuItem
                    key={option}
                    disabled={
                      !showNumberTypesForFormulaResult && !showNumberTypes
                    }
                    value={option}
                  >
                    {summaryNiceName(option as SummaryType)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <Autocomplete
              options={fieldsList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Group By" />
              )}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value?.id
              }}
              value={groupByField}
              onChange={(_, newValue) => {
                setGroupByField(newValue)
                setChartSettings({
                  ...chartSettings,
                  barChart: {
                    ...barChartSettings,
                    viewAxis: {
                      ...barChartSettings?.viewAxis,
                      groupBy: newValue,
                    },
                  },
                })
              }}
              loading={loadingViews}
              disabled={!selectedTable}
              renderOption={(props, option) => (
                <li {...props}>
                  <div className="flex items-center justify-between gap-4">
                    <IconoirProvider
                      iconProps={{
                        color: '#AAAAAA',
                        strokeWidth: 2,
                        width: '1em',
                        height: '1em',
                      }}
                    >
                      {getIconForField({ type: option?.type })}
                    </IconoirProvider>

                    <p>{option?.name}</p>
                  </div>
                </li>
              )}
            />
          </FormControl>
        </div>
      </div>
    </>
  )
}

export default BarChartDataSettings
