import React, { useState, useMemo, useEffect, useCallback } from 'react'

import { themeQuartz } from '@ag-grid-community/theming'
import { ApolloError } from '@apollo/client'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Box, Typography, useMediaQuery } from '@mui/material'
import {
  ColDef,
  ICellRendererParams,
  GridApi,
  GridReadyEvent,
  GetRowIdParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Button, Empty, Tag } from 'antd'
import dayjs from 'dayjs'
import { LeadCompany, LeadStatus } from 'types/graphql'

import LeadStatusChip from 'src/components/Ancil/LeadStatusChip'
import OpportunityChip from 'src/components/Ancil/OpportunityChip'
import PartnerChip from 'src/components/Ancil/PartnerChip'
import { TabsEnum } from 'src/components/Ancil/TabNavigation'
import Loading from 'src/components/Library/Loading'
import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

import ReferLeadModal from './ReferLeadModal/ReferLeadModal'

const ancilGridTheme = themeQuartz.withParams({
  accentColor: '#6366F1',
  browserColorScheme: 'light',
  headerFontSize: 14,
  sidePanelBorder: true,
  wrapperBorder: true,
})

interface AncilTableProps {
  ancillaryLeads?: AncillaryLead[]
  loading?: boolean
  error?: ApolloError
  activeTab?: TabsEnum
  query?: string
}

const AncilTable: React.FC<AncilTableProps> = React.memo(
  function MemoizedAncilTable({
    ancillaryLeads,
    loading,
    error,
    activeTab = TabsEnum.NAME,
    query,
  }) {
    const [gridApi, setGridApi] = useState<GridApi<AncillaryLead> | null>(null)
    const [isReferModalOpen, setIsReferModalOpen] = useState(false)
    const [selectedLead, setSelectedLead] = useState<AncillaryLead | null>(null)
    const isSmallDisplay = useMediaQuery('(max-width:1500px)')

    const reorderColumns = (api: GridApi<AncillaryLead>, tab: TabsEnum) => {
      if (!api) return

      if (tab === TabsEnum.NAME) {
        api.moveColumns(['decryptedName'], 0)
        api.moveColumns(['propertyAddress'], 2)
      } else if (tab === TabsEnum.PROPERTY) {
        api.moveColumns(['propertyAddress'], 0)
        api.moveColumns(['decryptedName'], 2)
      }
    }

    const colDefs: ColDef<AncillaryLead>[] = [
      {
        colId: 'decryptedName',
        headerName: 'Name',
        field: 'decryptedName',
      },
      {
        colId: 'triggerType',
        headerName: 'Opportunity',
        field: 'triggerType',
        flex: 1.1,
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <OpportunityChip opportunity={params.data.triggerType} />
        ),
      },
      {
        colId: 'propertyAddress',
        headerName: 'Property',
        field: 'propertyAddress',
        comparator: (a, b) => {
          const cleanString = (str: string) =>
            str.replace(/[\d\s\/]+/g, '').toLowerCase()
          const cleanA = cleanString(a)
          const cleanB = cleanString(b)
          return cleanA.localeCompare(cleanB)
        },
      },
      {
        colId: 'triggeredByUser',
        headerName: 'Agent(s)',
        field: 'extPrimaryAgentName',
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <Box gap={2}>
            <Typography variant={'body2'}>
              {params.data.extPrimaryAgentName}
            </Typography>
            {params.data.extSecondaryAgentName && (
              <Typography variant={'body2'} className={'text-gray-400'}>
                {params.data.extSecondaryAgentName}
              </Typography>
            )}
          </Box>
        ),
      },
      {
        colId: 'leadCompany',
        headerName: 'Partner',
        field: 'leadCompany',
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <PartnerChip partner={params.data.leadCompany as LeadCompany} />
        ),
      },
      {
        colId: 'createdAt',
        headerName: 'Generated On',
        field: 'createdAt',
        sort: 'desc',
        filter: 'agDateColumnFilter', // Add the date filter
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const cellDate = new Date(cellValue)
            // Remove time part for accurate comparison
            const cellDateOnly = new Date(
              cellDate.getFullYear(),
              cellDate.getMonth(),
              cellDate.getDate(),
            )
            if (cellDateOnly < filterLocalDateAtMidnight) {
              return -1
            } else if (cellDateOnly > filterLocalDateAtMidnight) {
              return 1
            } else {
              return 0
            }
          },
          // Optional: Specify the date format if needed
          // browserDatePicker: true,
        },
        cellRenderer: (params) => (
          <Box className="leading-snug">
            <Box>{dayjs(params.data.createdAt).format('Do MMM YYYY')}</Box>
            <Box className="text-gray-400">
              {dayjs(params.data.createdAt).format('h:mma')}
            </Box>
          </Box>
        ),
      },
      {
        colId: 'hasConsent',
        headerName: 'Has Consent',
        field: 'hasConsent',
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) =>
          params.data.hasConsent ? (
            <Box alignItems="center" display="flex">
              <CheckBoxIcon className="mr-1.5 text-emerald-500" />
              Consented
            </Box>
          ) : (
            <Box alignItems="center" display="flex">
              <CheckBoxOutlineBlankIcon className="mr-1.5 text-slate-300" />
              Not consented
            </Box>
          ),
      },
      {
        colId: 'leadStatus',
        headerName: 'Status',
        field: 'leadStatus',
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <LeadStatusChip status={params.data.leadStatus as LeadStatus} />
        ),
      },
      {
        colId: 'submittedBy',
        headerName: 'Submitted By',
        field: 'triggeredByUser.name',
        comparator: (a, b) => {
          if (a === undefined && b === undefined) return 0
          if (a === undefined) return 1
          if (b === undefined) return -1
          return a.localeCompare(b)
        },
        filter: true,
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) =>
          params.data.triggeredByUser?.name ? (
            <Box className="leading-snug">
              <Box>{params.data.triggeredByUser.name}</Box>
              <Box className="text-gray-400">
                {dayjs(params.data.triggeredAt).format('Do MMM YYYY')}
              </Box>
            </Box>
          ) : (
            <Tag />
          ),
      },
      {
        colId: 'actions',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        cellClass: 'flex justify-center items-center',
        filter: false,
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) =>
          params.data.triggeredAt ? (
            <Button
              type={'link'}
              onClick={() => {
                setSelectedLead(params.data)
                setIsReferModalOpen(true)
              }}
            >
              {'View Details'}
            </Button>
          ) : (
            <Button
              type={'primary'}
              onClick={() => {
                setSelectedLead(params.data)
                setIsReferModalOpen(true)
              }}
            >
              {'Refer Lead'}
            </Button>
          ),
        cellStyle: () => ({
          border: 'none', // Suppresses focus after clicking refer button
        }),
      },
    ]

    // Shared column configurations
    const defaultColDef = useMemo(
      () => ({
        sortable: true,
        resizable: true,
        cellClass: 'flex items-center',
        flex: isSmallDisplay ? undefined : 1,
        filter: true,
        filterParams: {
          buttons: ['reset'],
        },
      }),
      [isSmallDisplay],
    )

    const onGridReady = (params: GridReadyEvent) => {
      setGridApi(params.api)
      reorderColumns(params.api, activeTab)
    }

    const getRowId = useCallback(
      (params: GetRowIdParams<AncillaryLead>) => params.data.id.toString(),
      [],
    )

    useEffect(() => {
      if (gridApi) reorderColumns(gridApi, activeTab)
    }, [activeTab, gridApi])

    return (
      <div
        className="ag-theme-quartz"
        style={{ height: 'calc(100vh - 230px)', width: '100%' }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowData={ancillaryLeads || []}
          loading={loading}
          autoSizeStrategy={
            isSmallDisplay ? { type: 'fitCellContents' } : undefined
          }
          loadingOverlayComponent={() => <Loading />}
          overlayNoRowsTemplate={
            error
              ? 'Error Loading Leads - Please try again later or contact support'
              : 'No Leads Available'
          }
          pagination
          suppressScrollOnNewData
          paginationPageSize={50}
          getRowId={getRowId}
          rowBuffer={50}
          groupDisplayType="groupRows"
          theme={ancilGridTheme}
          gridOptions={{ rowHeight: 60 }}
          noRowsOverlayComponent={() => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'No leads to show'}
            />
          )}
          quickFilterText={query}
          groupDefaultExpanded={-1} // expand all groups by default
          suppressRowHoverHighlight // disables row background color highlight on hover
          suppressCellFocus // disables cell borders appearing on click
          suppressColumnVirtualisation // fixes column flickering issue on horizontal scroll
          loadThemeGoogleFonts={false} // stops console warning
        />
        {selectedLead && (
          <ReferLeadModal
            open={isReferModalOpen}
            onClose={() => setIsReferModalOpen(false)}
            lead={selectedLead}
          />
        )}
      </div>
    )
  },
)

export default AncilTable
