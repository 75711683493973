import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import * as React from 'react'

import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import {
  CreateAzureTokenMutation,
  CreateAzureTokenMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Library/Button/Button'
import { GENERATE_AZURE_AUTH_CODE } from 'src/components/SentimentAnalysis/SentimentAnalysisQueries'
import { openWindowWithBlockCheck } from 'src/lib/helpers'

enum ConnectButtonLabels {
  microsoft = 'Connect with Microsoft Azure',
  microsoftRequest = 'Request App Access',
}

const SettingsSentimentAnalysisRequestAuth: FC<{
  setClientAuthenticated: Dispatch<SetStateAction<boolean>>
}> = ({ setClientAuthenticated }) => {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false)

  // Set up BC Channel
  const [broadcastReceived, setBroadcastReceived] = useState<boolean>(false)

  // LISTEN for event broadcast on new tab
  const bc = new BroadcastChannel('sentiment_analysis_azure_authorisation')

  bc.onmessage = (event) => {
    if (event.data === 'Success') {
      setBroadcastReceived(true)
    }
  }

  // Create new azure token
  const [createAzureToken] = useMutation<
    CreateAzureTokenMutation,
    CreateAzureTokenMutationVariables
  >(GENERATE_AZURE_AUTH_CODE, {
    onCompleted: (result) => {
      setIsAuthenticating(false)
      openWindowWithBlockCheck(result.azureGenerateAppCodeRequest.url)
    },
    onError: () => {
      setIsAuthenticating(false)
    },
  })

  // Call this function to generate new token
  const generateAzureCode = async () => {
    setIsAuthenticating(true)
    await createAzureToken({
      variables: {
        input: {
          appName: 'EMAIL_SENTIMENT',
        },
      },
    })
  }

  // ON the BC event, trigger ReAuth or Refetch
  useEffect(() => {
    if (broadcastReceived) {
      setIsAuthenticating(false)
      setClientAuthenticated(true)
    }
  }, [broadcastReceived])

  const steps = [
    {
      label: 'Request Azure Connection',
      description: (
        <span>
          Click <b>{ConnectButtonLabels.microsoft}</b> to authenticate with your
          Microsoft Account. This will open in a new tab, and may prompt you to
          log in if you are not already.
        </span>
      ),
    },
    {
      label: 'Authorise with your IT Team',
      description: (
        <span>
          Click on
          <b> {ConnectButtonLabels.microsoftRequest} </b>
          to send an App Request to your IT Manager. They will need to approve
          the App Request to enable your the connection for your organisation.
        </span>
      ),
    },
    {
      label: 'Connect your Organisation',
      description: (
        <span>
          Return to this screen and click on
          <b> {ConnectButtonLabels.microsoft}</b> again. You will be taken to a
          new tab to authenticate, then returned to The Hub. Your Organisation
          is now connected to Email Sentiment.
        </span>
      ),
    },
  ]

  return (
    <div className="mx-auto max-w-4xl rounded-lg border border-gray-300 p-10 shadow-lg">
      <p className="mb-4 text-lg font-bold">
        Connect to Email Sentiment with Microsoft Azure
      </p>

      <Stepper orientation="vertical">
        {steps.map((step) => (
          <Step
            expanded
            active
            key={step.label}
            sx={{
              '& .MuiStepLabel-root svg.Mui-active': {
                color: '#4f46e5',
              },
            }}
          >
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      <Button
        className="mt-8"
        loading={isAuthenticating}
        onClick={() => {
          generateAzureCode()
        }}
        fullWidth={false}
        buttonDataTestId="settings-sentiment-analysis-request-auth-button"
      >
        {ConnectButtonLabels.microsoft}
      </Button>
    </div>
  )
}

export default SettingsSentimentAnalysisRequestAuth
