import React, { useState } from 'react'

import FilterIcon from '@mui/icons-material/FilterAltOutlined'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import { Badge } from 'antd'

import Switch from 'src/components/Library/Switch'

const renderRadioIcon = (isSelected: boolean) =>
  isSelected ? (
    <RadioButtonCheckedIcon fontSize="small" className={'text-indigo-600'} />
  ) : (
    <RadioButtonUncheckedIcon fontSize="small" />
  )

export const TableFilterIconButton = ({
  showHasConsentOnly,
  setShowHasConsentOnly,
  showActiveOnly,
  setShowActiveOnly,
}: {
  showHasConsentOnly: boolean | null
  setShowHasConsentOnly: React.Dispatch<React.SetStateAction<boolean | null>>
  showActiveOnly: boolean
  setShowActiveOnly: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton
        className={'bg-gray-100 hover:bg-gray-100'}
        onClick={handleMenuOpen}
        disableRipple
      >
        <Badge dot={showHasConsentOnly !== null || showActiveOnly !== true}>
          <FilterIcon />
        </Badge>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <Box px={2} py={1} fontWeight="bold">
          Consent
        </Box>
        <MenuItem
          selected={showHasConsentOnly === null}
          onClick={() => {
            setShowHasConsentOnly(null)
            handleMenuClose()
          }}
        >
          {renderRadioIcon(showHasConsentOnly === null)}
          <Box ml={1}>All</Box>
        </MenuItem>
        <MenuItem
          selected={showHasConsentOnly === true}
          onClick={() => {
            setShowHasConsentOnly(true)
            handleMenuClose()
          }}
        >
          {renderRadioIcon(showHasConsentOnly === true)}
          <Box ml={1}>Pre-Consented Only</Box>
        </MenuItem>
        <MenuItem
          selected={showHasConsentOnly === false}
          onClick={() => {
            setShowHasConsentOnly(false)
            handleMenuClose()
          }}
        >
          {renderRadioIcon(showHasConsentOnly === false)}
          <Box ml={1}>Non-Consented Only</Box>
        </MenuItem>
        <Divider />
        <FormControl className={'ml-5'}>
          <FormControlLabel
            label={'Closed Leads'}
            control={
              <Switch
                checked={!showActiveOnly}
                color={'success'}
                onChange={() => setShowActiveOnly(!showActiveOnly)}
                className={'mr-1'}
              />
            }
            data-intercom-target={'live-feed-toggle'}
          />
        </FormControl>
      </Menu>
    </Box>
  )
}
