import React, { FC, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import dayjs from 'dayjs'
import {
  GetBaserowTableFields,
  GetBaserowTableFieldsVariables,
} from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import AccordionOriginalRecord from 'src/components/Automations/AutomationLogModal/Components/AccordionOriginalRecord'
import ActionItem from 'src/components/Automations/AutomationLogModal/Components/ActionItem'
import RowDetails from 'src/components/Automations/AutomationLogModal/Components/RowDetails'
import SectionTitle from 'src/components/Automations/AutomationLogModal/Components/SectionTitle'
import {
  AutomationLogActionItemType,
  AutomationLogCriteriaResultsType,
  AutomationLogTriggerDataType,
  calculateActionLogStatus,
  eventTypeTranslator,
} from 'src/components/Automations/AutomationLogModal/utils'
import { getAppropriateDurationFormat } from 'src/components/Automations/AutomationLogSection'
import { BaserowField } from 'src/components/Automations/utils'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

import { AutomationBaserowData } from '../AutomationLayout'
import { GET_BASEROW_FIELDS } from '../queries'
import { EnrichedActionType } from '../utils'

import { AutomationLogType } from './AutomationLogDetailCell'
import BaserowTriggerRecordLists from './Components/BaserowTriggerRecordLists'

interface AutomationLogDetailsProps {
  automationLogRecord: AutomationLogType
  selectedAutomationBaserowData: AutomationBaserowData
}

const AutomationLogDetails: FC<AutomationLogDetailsProps> = ({
  automationLogRecord,
  selectedAutomationBaserowData,
}) => {
  // Need to cast these from JSON to correct the types
  const automationLogCriteriaResults =
    automationLogRecord?.criteriaResults as AutomationLogCriteriaResultsType
  const automationLogTriggerData =
    automationLogRecord?.triggerData as AutomationLogTriggerDataType
  const automationLogActionData =
    automationLogRecord?.actionData as AutomationLogActionItemType[]
  const automationLogRelatedAutomationAction = automationLogRecord.automation
    ?.action as unknown as EnrichedActionType[]

  // Baserow Fields for Automation Table
  const [baserowTableFields, setBaserowTableFields] = useState<BaserowField[]>(
    [],
  )

  const [getTableFields, { loading: loadingTableFields }] = useLazyQuery<
    GetBaserowTableFields,
    GetBaserowTableFieldsVariables
  >(GET_BASEROW_FIELDS, {
    onCompleted: (data) => {
      setBaserowTableFields(data.getBaserowTableFields as BaserowField[])
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
      })
    },
  })

  useEffect(() => {
    getTableFields({
      variables: {
        input: {
          tableId: String(selectedAutomationBaserowData?.table?.id),
          workspaceId: String(selectedAutomationBaserowData?.workspace?.id),
        },
      },
    })
  }, [automationLogRecord])

  return (
    <Box className={'h-full p-0'}>
      {loadingTableFields ? (
        <div className="grid h-full place-items-center py-32">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Stack
            direction={'row'}
            spacing={3}
            className="justify-between border-b px-6 py-3"
          >
            <SectionTitle
              sectionName={automationLogRecord.automation.name}
              sectionTooltip="The name of the automation that has run."
            />

            <RowDetails
              status={calculateActionLogStatus(automationLogRecord)}
              informationItems={[
                {
                  key: 'Run Time',
                  value: dayjs(
                    automationLogRecord.completedAt ||
                      automationLogRecord.createdAt,
                  ).format('DD/MM/YYYY h:mm A'),
                },
                {
                  key: 'Event Type',
                  value: eventTypeTranslator(automationLogRecord.eventType),
                },
                {
                  key: 'Duration',
                  value: getAppropriateDurationFormat(
                    automationLogRecord.createdAt,
                    automationLogRecord.completedAt,
                  ),
                },
              ]}
            />
          </Stack>
          <Stack spacing={2} className="border-b px-6 py-3">
            <SectionTitle
              sectionName="Original Record"
              sectionTooltip="The values of the fields in the record before the automation ran."
            />

            <AccordionOriginalRecord
              recordData={automationLogTriggerData.originalData}
            />
          </Stack>

          <Stack spacing={1} className="border-b px-6 py-3">
            <Stack direction={'row'} className="justify-between pb-1">
              <SectionTitle
                sectionName="Trigger Step"
                sectionTooltip="The step that checks if the automation should run based on the row that triggered it."
              />

              <RowDetails
                status="COMPLETED"
                informationItems={[
                  {
                    key: 'Row',
                    value: automationLogTriggerData.triggerRowId.toString(),
                  },
                  {
                    key: 'Table',
                    value: automationLogTriggerData.triggerTableName,
                  },
                  {
                    key: 'Base',
                    value: automationLogTriggerData.triggerBaseId.toString(),
                  },
                  {
                    key: 'View',
                    value: automationLogTriggerData.triggerViewName,
                  },
                ]}
              />
            </Stack>

            <BaserowTriggerRecordLists
              automationLogCriteriaResults={automationLogCriteriaResults}
            />
          </Stack>

          <Stack spacing={1} className="border-b px-6 py-3">
            <Stack
              direction={'row'}
              spacing={2}
              className="justify-between pb-1"
            >
              <SectionTitle
                sectionName="Action Steps"
                sectionTooltip="The actions performed by the automation. There can be multiple."
              />

              <RowDetails
                status={calculateActionLogStatus(automationLogRecord)}
                informationItems={[]}
              />
            </Stack>

            <div className="flex w-full flex-col justify-between gap-4">
              {automationLogActionData?.map((actionData, index) => (
                <ActionItem
                  key={index}
                  index={index}
                  actionData={actionData}
                  baserowTableFields={baserowTableFields}
                  automationLogRelatedAutomationAction={
                    automationLogRelatedAutomationAction
                  }
                />
              ))}
            </div>
          </Stack>

          {['FAILED', 'PARTIAL'].includes(automationLogRecord.status) && (
            <Stack spacing={1} className="border-b px-6 py-3">
              <Stack direction={'row'} className="justify-between pb-1">
                <SectionTitle
                  sectionName="Errors"
                  sectionTooltip="Some errors occurred during the automation run."
                />
              </Stack>

              <div className="flex w-full flex-col justify-between gap-4">
                {automationLogActionData?.map((actionData, index) => (
                  <Stack
                    key={index}
                    spacing={2}
                    className={`w-full border-2 ${!actionData.actionSuccess && 'border-red-500'} p-2`}
                  >
                    <Stack
                      direction={'row'}
                      spacing={2}
                      className="justify-between pb-1"
                    >
                      <SectionTitle sectionName={`Action No. ${index + 1}`} />
                    </Stack>
                    {actionData.actionSuccess ? (
                      <Typography key={index}>No Errors Encountered</Typography>
                    ) : (
                      <Typography key={index}>{actionData.message}</Typography>
                    )}
                  </Stack>
                ))}
              </div>
            </Stack>
          )}
        </>
      )}
    </Box>
  )
}

export default AutomationLogDetails
