import React, { FC } from 'react'

import Stack from '@mui/material/Stack'

import { AutomationLogFieldStringType } from 'src/components/Automations/AutomationLogModal/utils'
import Accordion from 'src/components/Library/Accordion'

import DetailValue from './DetailView'

interface AccordionOriginalRecordProps {
  recordData: AutomationLogFieldStringType
}

const AccordionOriginalRecord: FC<AccordionOriginalRecordProps> = ({
  recordData,
}) => {
  return (
    <Accordion
      removeDetailsPadding
      title={<p className="font-light">Original Record Data</p>}
      customstyle="before:hidden !border-0 bg-white py-0 !overflow-hidden !rounded-0"
      borderstyle="!border before:hidden !rounded-0 !overflow-hidden"
    >
      <div className="bg-gray-50 p-6">
        <Stack direction={'row'} spacing={2} className="mb-2 border-b pb-1">
          <DetailValue setWidth>
            <b>Field</b>
          </DetailValue>
          <DetailValue setWidth>
            <b>Value</b>
          </DetailValue>
        </Stack>
        {recordData &&
          Object.entries(recordData).map(([key, value], id) => (
            <Stack key={id} direction={'row'} spacing={2}>
              <DetailValue setWidth>{key}</DetailValue>
              <DetailValue setWidth>
                {value.truncatedStringValue || '-'}
              </DetailValue>
            </Stack>
          ))}
      </div>
    </Accordion>
  )
}

export default AccordionOriginalRecord
