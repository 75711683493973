import React from 'react'

import { Chip, ChipProps } from '@mui/material'
import { LeadCompany } from 'types/graphql'

interface Props extends ChipProps {
  partner: LeadCompany
}

export default function PartnerChip({
  partner,
  className: additionalClassNames,
  ...rest
}: Props): React.ReactElement {
  const partnerClassNames: Record<LeadCompany, string> = {
    ['ON_COVER']: 'bg-indigo-100 text-indigo-600',
    ['COMPARE_CONNECT']: 'bg-green-100 text-green-600',
    ['RELLO']: 'bg-red-100 text-red-600',
    ['INNOVAYT']: 'bg-red-100 text-red-600',
    ['BOND']: 'bg-green-100 text-green-600',
  }

  const partnerHumanNames: Record<LeadCompany, string> = {
    ['ON_COVER']: 'On Cover Insurance',
    ['COMPARE_CONNECT']: 'Compare & Connect Connections',
    ['RELLO']: 'Rello Funding',
    ['INNOVAYT']: 'Innovayt Finance',
    ['BOND']: 'Bond Conveyancing',
  }

  return (
    <Chip
      size={'small'}
      label={partnerHumanNames[partner] || partner}
      className={`rounded-xl px-1 py-2 capitalize ${partnerClassNames[partner] || 'bg-gray-300 text-black'} ${additionalClassNames}`}
      {...rest}
    />
  )
}
