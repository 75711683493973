import React, { useEffect, useState } from 'react'

import { Box, Checkbox, Typography } from '@mui/material'
import { Descriptions, Divider, message } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { ReferLead, ReferLeadVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { REFER_LEAD } from 'src/components/Ancil/gql/mutations'
import { ANCIL_TABLE_QUERY } from 'src/components/Ancil/gql/queries'
import OpportunityChip from 'src/components/Ancil/OpportunityChip'
import PartnerChip from 'src/components/Ancil/PartnerChip'
import AdditionalRequirementsForm from 'src/components/Ancil/ReferLeadModal/AdditionalRequirementsForm'
import { additionalRequiredInformation } from 'src/components/Ancil/utils'
import Modal from 'src/components/Modal'
import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

export const CONSENT_CHECKBOX_DATA_TEST_ID = 'consent-checkbox'

interface Props {
  open: boolean
  onClose: () => void
  lead: AncillaryLead
}

export default function ReferLeadModal({
  open,
  onClose,
  lead,
}: Props): React.ReactElement {
  const [payload, setPayload] = useState<
    Record<string, string | number | Dayjs>
  >({})
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const {
    hasConsent,
    decryptedName,
    triggerType,
    triggeredAt,
    leadCompany,
    propertyAddress,
    triggeredByUser,
    extPrimaryAgentName,
    extSecondaryAgentName,
    createdAt,
  } = lead
  const [consentChecked, setConsentChecked] = useState(hasConsent)

  const [referLead, { loading }] = useMutation<ReferLead, ReferLeadVariables>(
    REFER_LEAD,
    {
      onCompleted: () => {
        message.open({
          type: 'success',
          content: 'Successfully referred lead',
        })
        onClose()
      },
      refetchQueries: [ANCIL_TABLE_QUERY],
      onError: () =>
        message.open({ type: 'error', content: 'Failed to refer lead' }),
    },
  )

  const hasLeadBeenSubmitted = !!triggeredByUser
  const additionalRequirements =
    additionalRequiredInformation[leadCompany]?.[triggerType] || []

  useEffect(() => {
    setConsentChecked(lead.hasConsent)
  }, [lead])

  useEffect(() => {
    if (!additionalRequirements.length) return
    setPayload(
      additionalRequirements.reduce(
        (acc, req) => {
          acc[req.dataKey] = ''
          return acc
        },
        {} as Record<string, string | number | Dayjs>,
      ),
    )
  }, [additionalRequirements])

  const handleClose = () => {
    setPayload({})
    setAttemptedSubmit(false)
    onClose()
  }

  const handleRefer = () => {
    setAttemptedSubmit(true)
    const missingRequired = additionalRequirements.some(
      (req) => req.required && !payload[req.dataKey],
    )
    if (missingRequired || !consentChecked) return
    return referLead({
      variables: {
        id: lead.id,
        input: { hasConsent: true },
      },
    })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={hasLeadBeenSubmitted ? 'Lead Submission Details' : 'Refer Lead'}
      className="mt-20 !w-[600px]"
      closeButtonVisible
      footerVisible={!hasLeadBeenSubmitted}
      confirmText="Refer Lead"
      cancelText="Cancel"
      onConfirm={handleRefer}
      onCancel={handleClose}
      confirmDisabled={!consentChecked}
      loading={loading}
    >
      <Box p={3} pt={1}>
        <Box>
          <Typography variant="h4" className={'text-3xl'}>
            {decryptedName}
          </Typography>
          <Typography className="mt-2">{propertyAddress}</Typography>
        </Box>
        <Box className="mt-3" gap={1} display="flex">
          <OpportunityChip opportunity={triggerType} />
          <PartnerChip partner={leadCompany} />
        </Box>
        <Box className="mt-5">
          <Descriptions size="small" layout="horizontal" column={1} bordered>
            <Descriptions.Item
              label={!extSecondaryAgentName ? 'Agent' : 'Agents'}
              labelStyle={{ width: 150 }}
            >
              {`${extPrimaryAgentName}${extSecondaryAgentName ? `, ${extSecondaryAgentName}` : ''}`}
            </Descriptions.Item>
            <Descriptions.Item label="Generated on">
              {dayjs(createdAt).format('Do MMM YYYY, h:mma')}
            </Descriptions.Item>
            {hasLeadBeenSubmitted && (
              <>
                <Descriptions.Item label="Submitted by">
                  {triggeredByUser?.name || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Submitted on">
                  {triggeredAt
                    ? dayjs(triggeredAt).format('Do MMM YYYY, h:mma')
                    : '-'}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </Box>
        <AdditionalRequirementsForm
          payload={payload}
          additionalRequirements={additionalRequirements}
          onPayloadChange={setPayload}
          attemptedSubmit={attemptedSubmit}
        />
        <Divider />
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography>Consent Acknowledgement</Typography>
          {hasLeadBeenSubmitted && (
            <Typography
              variant={'subtitle2'}
              style={{ fontSize: 12 }}
              className={'text-slate-400'}
            >
              {`Consent Acknowledged ${dayjs(triggeredAt).format('Do MMM YYYY, h:mma')}`}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          className="mt-3 cursor-pointer bg-slate-100"
          flexDirection={'row'}
          alignItems={'center'}
          p={1.5}
          borderRadius={2}
          gap={1.5}
          onClick={() => {
            if (hasLeadBeenSubmitted) return
            setConsentChecked(!consentChecked)
          }}
        >
          {!hasLeadBeenSubmitted && (
            <Checkbox
              data-testid={CONSENT_CHECKBOX_DATA_TEST_ID}
              checked={consentChecked}
            />
          )}
          {hasLeadBeenSubmitted ? (
            <Typography style={{ fontSize: 14 }} className="text-slate-600">
              The client provided their informed consent for their details to be
              used in connection with this lead. This confirmation ensured the
              information was shared in accordance with their authorisation and
              expectations.
            </Typography>
          ) : (
            <Typography style={{ fontSize: 14 }} className="text-slate-600">
              The client has provided their informed consent for their details
              to be used in connection with this lead. By proceeding, you
              confirm that the client has authorised the sharing of their
              information for the purposes outlined.
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
