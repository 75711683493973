import React, { FC } from 'react'

import Stack from '@mui/material/Stack'

import { getTableFieldName } from 'src/components/Automations/AutomationLogModal/utils'
import {
  BaserowField,
  EnrichedActionType,
} from 'src/components/Automations/utils'

import DetailValue from './DetailView'

interface ActionItemRowProps {
  fieldIdFull: string
  value?: string
  baserowTableFields: BaserowField[]
  automationLogRelatedAutomationAction: EnrichedActionType[]
}

const ActionItemRow: FC<ActionItemRowProps> = ({
  fieldIdFull,
  value,
  baserowTableFields,
  automationLogRelatedAutomationAction,
}) => {
  if (!fieldIdFull) return
  return (
    <Stack direction={'row'} spacing={2}>
      <DetailValue setWidth>
        {getTableFieldName({
          fieldIdFull,
          automationLogRelatedAutomationAction,
          baserowTableFields,
        })}
      </DetailValue>
      <DetailValue setWidth>{value || '-'}</DetailValue>
    </Stack>
  )
}

export default ActionItemRow
