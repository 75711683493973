// Desktop filter controls component
import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { Box, FormControl, FormControlLabel } from '@mui/material'
import { Radio } from 'antd'

import Switch from 'src/components/Library/Switch'

export const DesktopFilterControls = ({
  showHasConsentOnly,
  setShowHasConsentOnly,
  showActiveOnly,
  setShowActiveOnly,
}: {
  showHasConsentOnly: boolean | null
  setShowHasConsentOnly: React.Dispatch<React.SetStateAction<boolean | null>>
  showActiveOnly: boolean
  setShowActiveOnly: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <Box display={'flex'} gap={3} alignItems={'center'}>
      <Radio.Group
        value={showHasConsentOnly}
        buttonStyle="solid"
        onChange={(e) => setShowHasConsentOnly(e.target.value)}
      >
        <Radio.Button value={null}>All</Radio.Button>
        <Radio.Button value={true}>
          <DoneAllIcon className={'mr-2 h-4 w-4'} />
          {'Pre-Consented Only'}
        </Radio.Button>
        <Radio.Button value={false}>
          <CloseIcon className={'mr-2 h-4 w-4'} />
          {'Non-Consented Only'}
        </Radio.Button>
      </Radio.Group>
      <Box>
        <FormControl className={''}>
          <FormControlLabel
            label={'Include Closed Leads'}
            control={
              <Switch
                checked={!showActiveOnly}
                color={'success'}
                onChange={() => setShowActiveOnly(!showActiveOnly)}
                className={'mr-1'}
              />
            }
            data-intercom-target={'live-feed-toggle'}
          />
        </FormControl>
      </Box>
    </Box>
  )
}
