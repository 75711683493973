import type { FC } from 'react'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import type {
  HorizontalAlignmentType,
  VerticalAlignmentType,
} from 'recharts/types/component/DefaultLegendContent'
import type { LayoutType } from 'recharts/types/util/types'

import { trimToCharLength } from 'src/Util'

import { AXIS_HEADING_CHAR_LIMIT, BAR_CHART_LIMIT } from '../lib/constants'
import { CardType } from '../lib/enums'
import type {
  AnyBaserowField,
  BarChartSettings,
  HubDashCardSettings,
  HubDashCardType,
} from '../lib/types'

import {
  getChartData,
  type ChartItem,
  type ChartSortSettings,
  type GroupOption,
} from './ChartFunctions/getChartData'
import ChartLegend from './ChartLegend'
import ChartTooltip from './ChartTooltip'

dayjs.extend(customParseFormat)

const getBarChartData = (
  barChartSettings: BarChartSettings,
  dataField: AnyBaserowField,
  cardSocketRecords: any[], // not typed yet
  cardSocketTableFields: any[], // not typed yet
  groupByField: AnyBaserowField,
): {
  bars: ChartItem[]
  groups: GroupOption[]
  barDataKey: string
  axisDataKey: string
} => {
  const returnObject = {
    bars: [] as ChartItem[],
    groups: [] as GroupOption[],
    barDataKey: 'value',
    axisDataKey: 'name',
  }

  // Find matching field
  const matchingField = cardSocketTableFields?.find(
    (field) => field?.id === dataField?.id,
  )

  const sortSettings: ChartSortSettings = {
    sortBy: barChartSettings?.sourceAxis?.sortBy ?? 'name',
    sortOrder: barChartSettings?.sourceAxis?.order ?? 'default',
  }

  const aggregateField = barChartSettings?.viewAxis?.field

  const { chartDataItems, groupByOptions } = getChartData(
    matchingField,
    cardSocketRecords,
    CardType.CHART_BAR,
    barChartSettings?.viewAxis?.aggregate,
    barChartSettings?.viewAxis?.includeEmptyRecords,
    barChartSettings?.sourceAxis?.bucketValuesBy,
    sortSettings,
    groupByField,
    aggregateField,
  )

  returnObject.bars = chartDataItems
  returnObject.groups = groupByOptions

  return returnObject
}

interface CardContentChartBarProps {
  cardSocket: any // not typed yet
  card: HubDashCardType
}

const CardContentChartBar: FC<CardContentChartBarProps> = ({
  cardSocket,
  card,
}) => {
  const cardSettings = card?.cardSettings as HubDashCardSettings
  const chartSettings = cardSettings?.chartSettings

  const barChartSettings = chartSettings?.barChart
  const headingSettings = chartSettings?.headings

  // Legend.visible can be undefined, true, false
  const legendVisible = chartSettings?.legend?.visible !== false
  const legendPosition = chartSettings?.legend?.position

  const legendAlignHorizontal =
    legendPosition === 'left' || legendPosition === 'right'
      ? legendPosition
      : 'right'
  const legendAlignVertical =
    legendPosition === 'top' || legendPosition === 'bottom'
      ? legendPosition
      : 'top'

  const horizontalLegend = ['top', 'bottom'].includes(legendPosition)
  const horizontalChart = barChartSettings?.layout?.orientation !== 'vertical'

  const dataField = chartSettings?.category
  const groupByField = chartSettings?.barChart?.viewAxis?.groupBy

  const barChartData = getBarChartData(
    barChartSettings,
    dataField,
    cardSocket?.records,
    cardSocket?.table?.fields,
    groupByField,
  )

  if (cardSocket.records.length === 0 || barChartData?.bars?.length === 0) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p className="text-gray-500">No data available</p>
      </div>
    )
  }

  if (groupByField && barChartData?.groups?.length === 0) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p className="text-gray-500">No grouping options available</p>
      </div>
    )
  }

  if (barChartData?.bars?.length > BAR_CHART_LIMIT) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p className="text-gray-500">There are too many records to display.</p>
      </div>
    )
  }

  // set XAxis interval to 0 if there are less than 40 data points to show all ticks
  const customInterval = 4
  let maxTickLengthExceeded = false
  if (barChartData.bars.length > 60) {
    maxTickLengthExceeded = true
  }

  return (
    <>
      <ResponsiveContainer
        width="99%"
        height="99%"
        debounce={100}
        className="p-2"
      >
        <BarChart
          data={barChartData.bars}
          layout={barChartSettings?.layout?.orientation as LayoutType}
          margin={{ top: 10, right: 25, bottom: 45, left: 45 }}
        >
          <XAxis
            hide={!headingSettings?.xAxis}
            tick={{ fill: cardSettings.appearance.textColor.hex ?? '#737373' }}
            dataKey={horizontalChart ? barChartData.axisDataKey : null}
            height={55}
            angle={-35}
            label={{
              value: trimToCharLength(
                headingSettings?.xAxis ?? '',
                AXIS_HEADING_CHAR_LIMIT,
              ),
              offset: -20,
              position: 'insideBottom',
              style: {
                fontWeight: 'bold',
                fill: cardSettings.appearance.textColor.hex ?? '#737373',
              },
            }}
            interval={maxTickLengthExceeded ? customInterval : 0}
            textAnchor="end"
            type={horizontalChart ? 'category' : 'number'}
            dy={10}
          />
          <YAxis
            hide={!headingSettings?.yAxis}
            tick={{
              fill: cardSettings.appearance.textColor.hex ?? '#737373',
              width: '20px',
            }}
            type={horizontalChart ? 'number' : 'category'}
            dataKey={horizontalChart ? null : barChartData.axisDataKey}
            width={55}
            label={{
              value: trimToCharLength(
                headingSettings?.yAxis ?? '',
                AXIS_HEADING_CHAR_LIMIT,
              ),
              offset: -20,
              angle: -90,
              dx: 0,
              position: 'left',
              style: {
                textAnchor: 'middle',
                fontWeight: 'bold',

                fill: cardSettings.appearance.textColor.hex,
              },
            }}
          />

          <Tooltip
            filterNull={false}
            content={
              <ChartTooltip
                groupOptions={barChartData?.groups}
                isGrouped={!!groupByField}
              />
            }
          />
          {!!groupByField &&
            barChartData?.groups?.map((group) => (
              <Bar
                key={group?.name}
                dataKey={group?.name}
                stackId="group"
                fill={group?.hex}
              ></Bar>
            ))}

          {!groupByField && (
            <Bar dataKey={barChartData?.barDataKey}>
              {barChartData?.bars.map((entry, index) => (
                <Cell fill={entry.hex} key={`cell-${index}-${entry.value}`} />
              ))}
            </Bar>
          )}

          {legendVisible && (
            <Legend
              content={
                <ChartLegend
                  data={barChartData.bars}
                  isHorizontal={horizontalLegend}
                  isGrouped={!!groupByField}
                  groupOptions={barChartData?.groups}
                  textColor={cardSettings.appearance.textColor.hex ?? '#737373'}
                />
              }
              layout={horizontalLegend ? 'horizontal' : 'vertical'}
              align={legendAlignHorizontal as HorizontalAlignmentType}
              verticalAlign={legendAlignVertical as VerticalAlignmentType}
              wrapperStyle={{
                padding: '1rem',
                paddingTop: legendAlignVertical === 'top' ? '0rem' : '1rem',
                paddingBottom: legendAlignVertical === 'top' ? '1rem' : '0rem',
                height: horizontalLegend ? '30%' : '100%',
              }}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default CardContentChartBar
