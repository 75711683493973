import { FC, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Drawer, Tooltip } from '@mui/material'
import {
  automationType,
  BaserowWorkspaces,
  CreateAutomation,
  CreateAutomationInput,
  CreateAutomationVariables,
  DeleteAutomation,
  DeleteAutomationVariables,
  GetBaserowWorkspacesBasesTablesList,
  GetBaserowWorkspacesBasesTablesListVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useConfirm } from 'src/lib/hooks/Confirmation'
import useBoolean from 'src/lib/hooks/UseBoolean'

import IconButton from '../Library/IconButton/IconButton'
import Loading from '../Library/Loading/Loading'

import { CellAutomation, CellAutomationCategory } from './AutomationCell'
import AutomationDetails from './AutomationDetails/AutomationDetails'
import AutomationEditActionForm from './AutomationEditForms/AutomationEditActionForm'
import AutomationEditFilterForm from './AutomationEditForms/AutomationEditFilterForm'
import AutomationEditTriggerForm from './AutomationEditForms/AutomationEditTriggerForm'
import AutomationFetchLoading from './AutomationFetchLoading'
import AutomationHistory from './AutomationHistory/AutomationHistory'
import AutomationHistoryModal from './AutomationLogModal/AutomationHistoryModal'
import CreateAutomationModal from './AutomationModals/CreateAutomationModal'
import AutomationListSidebar from './AutomationsList/AutomationListSidebar'
import AutomationToolbar from './AutomationToolbar'
import AutomationTrack from './AutomationTrack/AutomationTrack'
import AutomationTrackEmpty from './AutomationTrack/AutomationTrackEmpty'
import {
  CREATE_AUTOMATION,
  DELETE_AUTOMATION,
  GET_BASEROW_WORKSPACES_BASES_TABLES_LIST,
} from './queries'
import { MatchCriteriaType } from './utils'

export enum SettingsSidebarState {
  HISTORY = 'history',
  DETAILS = 'details',
  EDIT = 'edit',
}

export enum SettingsSidebarEditState {
  TRIGGER = 'trigger',
  FILTER = 'filter',
  ACTION = 'action',
}

export interface AutomationBaserowData {
  workspace: {
    id: number
    name: string
  }
  base: {
    id: number
    name: string
    inUseElsewhere: boolean
  }
  table: {
    id: number
    name: string
  }
}

interface AutomationLayoutProps {
  automationCategories: CellAutomationCategory[]
  uncategorisedAutomations: CellAutomationCategory[]
}

const getSelectedAutomationBaserowDetails = (
  workspaces: BaserowWorkspaces[],
  savedTableId: number,
): AutomationBaserowData => {
  const automationBaserowData: AutomationBaserowData = {
    workspace: { id: null, name: null },
    base: { id: null, name: null, inUseElsewhere: false },
    table: { id: null, name: null },
  }

  for (const workspace of workspaces) {
    for (const base of workspace.databases) {
      for (const table of base.tables) {
        if (Number(table.id) === Number(savedTableId)) {
          automationBaserowData.workspace = {
            id: workspace.workspaceId,
            name: workspace.workspaceName,
          }
          automationBaserowData.base = {
            id: base.id,
            name: base.name,
            inUseElsewhere: base.inUseElsewhere,
          }
          automationBaserowData.table = {
            id: table.id,
            name: table.name,
          }
          break
        }
      }
    }
  }

  return automationBaserowData
}

const searchCategoriesForSelectedAutomation = (
  automationCategories: CellAutomationCategory[],
  selectedAutomationId: number,
): CellAutomation => {
  let returnAutomation = null

  for (const category of automationCategories) {
    // Check all the sub categories
    if (category.childCategories?.length > 0) {
      const foundAutomation = searchCategoriesForSelectedAutomation(
        category.childCategories as CellAutomationCategory[],
        selectedAutomationId,
      )

      if (foundAutomation?.id) {
        returnAutomation = foundAutomation
      }
    }

    // Check the automations
    for (const automation of category.automations) {
      if (automation.id === selectedAutomationId) {
        returnAutomation = automation
        break
      }
    }
  }

  return returnAutomation
}

const AutomationLayout: FC<AutomationLayoutProps> = ({
  automationCategories,
  uncategorisedAutomations,
}) => {
  // Sidebar States
  const automationsListExpanded = useBoolean(true) // Left Sidebar
  const automationsSettingsExpanded = useBoolean(false) // Right Sidebar

  // What mode is the setting sidebar in
  const [settingsSidebarState, setSettingsSidebarState] =
    useState<SettingsSidebarState>(SettingsSidebarState.DETAILS)

  // What automation part are you editing
  const [settingsSidebarEditState, setSettingsSidebarEditState] =
    useState<SettingsSidebarEditState>(null)

  // Create Automation States
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [createAutomationInCategoryId, setCreateAutomationInCategoryId] =
    useState<number>(null)

  // Baserow Data
  const [baserowLoading, setBaserowLoading] = useState<boolean>(true)
  const [baserowResults, setBaserowResults] = useState<BaserowWorkspaces[]>([])

  const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false)
  const [selectedRecordId, setSelectedRecordId] = useState<number>(null)

  const [selectedAutomation, setSelectedAutomation] =
    useState<CellAutomation>(null)
  const [selectedAutomationBaserowData, setSelectedAutomationBaserowData] =
    useState<AutomationBaserowData>({
      workspace: { id: null, name: null },
      base: { id: null, name: null, inUseElsewhere: false },
      table: { id: null, name: null },
    })

  const [getBaserowWorkspacesBasesTables] = useLazyQuery<
    GetBaserowWorkspacesBasesTablesList,
    GetBaserowWorkspacesBasesTablesListVariables
  >(GET_BASEROW_WORKSPACES_BASES_TABLES_LIST, {
    onCompleted: (data) => {
      setBaserowLoading(false)

      setSelectedAutomationBaserowData(
        getSelectedAutomationBaserowDetails(
          data.getBaserowWorkspaceAndBaseList,
          Number(selectedAutomation?.automationResourceId),
        ),
      )
      setBaserowResults(data.getBaserowWorkspaceAndBaseList)
    },
    onError: (error) => {
      setBaserowLoading(false)
      toast.error(error.message, {
        duration: 2000,
      })
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  const [deleteAutomationIsLoading, setDeleteAutomationIsLoading] =
    useState<boolean>(false)

  const [duplicateAutomationIsLoading, setDuplicateAutomationIsLoading] =
    useState<boolean>(false)

  const [deleteAutomation] = useMutation<
    DeleteAutomation,
    DeleteAutomationVariables
  >(DELETE_AUTOMATION, {
    onCompleted: () => {
      setSelectedAutomation(null)
      toast.success('Automation Deleted')
      setDeleteAutomationIsLoading(false)
    },
    onError: () => {
      toast.error('There was a problem deleting your Automation.')
      setDeleteAutomationIsLoading(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindAutomationDashboardQuery'],
  })

  const [duplicateAutomation] = useMutation<
    CreateAutomation,
    CreateAutomationVariables
  >(CREATE_AUTOMATION, {
    onCompleted: (data) => {
      toast.success('Automation Duplicated')
      setSelectedAutomation(data.createAutomation as CellAutomation)
      setDuplicateAutomationIsLoading(false)
    },
    onError: () => {
      toast.error('There was a problem duplicating your Automation.')
      setDuplicateAutomationIsLoading(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindAutomationDashboardQuery'],
  })

  const confirmDelete = useConfirm()

  const handleCreateAutomationModalOpen = (categoryId: number) => {
    setCreateAutomationInCategoryId(categoryId)
    setCreateModalOpen(true)
  }

  const handleAutomationDelete = (id) => {
    confirmDelete({
      title: 'Delete Automation',
      description: 'Are you sure you want to delete this automation?',
      confirmationText: 'Delete',
      confirmationButtonProps: { color: 'error', className: 'bg-red-500' },
    }).then(() => {
      setDeleteAutomationIsLoading(true)
      deleteAutomation({
        variables: {
          id,
        },
      })
    })
  }

  const handleOpenDetails = () => {
    automationsSettingsExpanded.setTrue()
    setSettingsSidebarState(SettingsSidebarState.DETAILS)
  }

  const handleOpenHistory = () => {
    automationsSettingsExpanded.setTrue()
    setSettingsSidebarState(SettingsSidebarState.HISTORY)
  }

  const handleOpenEdit = (editMode: SettingsSidebarEditState) => {
    automationsSettingsExpanded.setTrue()
    setSettingsSidebarState(SettingsSidebarState.EDIT)
    setSettingsSidebarEditState(editMode)
    if (editMode !== settingsSidebarEditState) {
      setBaserowLoading(true)
    }
  }

  const handleDuplicateAutomation = async () => {
    setDuplicateAutomationIsLoading(true)

    const matchCriteria: MatchCriteriaType = {
      RECORD: {
        useViewsAsFilters: null,
        selectedView: null,
        requirements: [],
      },
      FIELD: {
        fieldsToWatch: [],
        restrictToView: null,
      },
      advancedLogic: {
        useAdvancedLogic: false,
        advancedLogicType: null,
        FIND: {
          linkedFieldData: null,
          filters: [],
          TYPE: null,
        },
      },
    }

    const inputData: CreateAutomationInput = {
      name: 'Copy - ' + selectedAutomation?.name,
      description: selectedAutomation?.description,
      onCreateTrigger: selectedAutomation?.onCreateTrigger,
      onDeleteTrigger: selectedAutomation?.onDeleteTrigger,
      onUpdateTrigger: selectedAutomation?.onUpdateTrigger,
      triggerType: selectedAutomation?.triggerType,
      matchCriteria: selectedAutomation?.matchCriteria ?? matchCriteria,
      action: selectedAutomation?.action ?? [],
      automationResourceId: selectedAutomation?.automationResourceId,
      automationResourceType: 'BASEROW' as automationType,
      automationCategoryId: selectedAutomation?.automationCategoryId,
    }

    await duplicateAutomation({
      variables: {
        inputData,
      },
    })
  }

  useEffect(() => {
    // Rehydrate the UI because we refetched
    if (selectedAutomation) {
      const findSelectedAutomation = searchCategoriesForSelectedAutomation(
        automationCategories,
        selectedAutomation.id,
      )
      if (findSelectedAutomation?.id) {
        setSelectedAutomation(findSelectedAutomation)
      } else {
        // Automation not found in list - reset state
        setSelectedAutomation(null)
        automationsSettingsExpanded.setFalse()
      }
    }
  }, [automationCategories])

  useEffect(() => {
    if (
      selectedAutomation &&
      (settingsSidebarState === SettingsSidebarState.EDIT ||
        settingsSidebarState === SettingsSidebarState.HISTORY)
    ) {
      // Refetch Data
      setBaserowLoading(true)

      getBaserowWorkspacesBasesTables()
    }
  }, [selectedAutomation, settingsSidebarState, settingsSidebarEditState])

  useEffect(() => {
    automationsSettingsExpanded.setFalse()
  }, [selectedAutomation])

  const handleViewDetails = (id: number) => {
    setSelectedRecordId(id)
    setHistoryModalOpen(true)
  }

  const handleModalClose = () => {
    setSelectedRecordId(null)
    setHistoryModalOpen(false)
  }

  useEffect(() => {
    if (automationsSettingsExpanded.value) {
      window.Intercom('update', {
        alignment: 'left',
      })
    } else {
      window.Intercom('update', {
        alignment: 'right',
      })
    }
  }, [automationsSettingsExpanded.value])

  return (
    <>
      <div className="flex w-full grow overflow-hidden overflow-y-scroll bg-gray-100">
        <AutomationListSidebar
          automationCategories={automationCategories}
          uncategorisedAutomations={uncategorisedAutomations}
          showFolderList={automationsListExpanded.value}
          selectedAutomation={selectedAutomation}
          setSelectedAutomation={setSelectedAutomation}
          handleCreateAutomationModalOpen={handleCreateAutomationModalOpen}
        />
        {!selectedAutomation && <AutomationTrackEmpty />}
        {selectedAutomation && (
          <div
            className={'flex w-full flex-1 grow flex-col'}
            style={{
              transitionTimingFunction: 'ease-in-out',
              transitionDuration: '300ms',
            }}
          >
            <div className="flex h-[75px] w-full grow items-center gap-4 border-b bg-white px-4">
              <div className="flex h-[75px] items-center">
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    automationsListExpanded.value
                      ? 'Hide Automations List'
                      : 'Show Automations List'
                  }
                >
                  <div>
                    <IconButton
                      className={
                        'flex-shrink rounded-lg bg-gray-100 p-2 hover:!bg-gray-200'
                      }
                      onClick={() => {
                        automationsListExpanded.toggle()
                      }}
                    >
                      {automationsListExpanded.value ? (
                        <ChevronLeftIcon className="h-4 w-4" />
                      ) : (
                        <ChevronRightIcon className="h-4 w-4" />
                      )}
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
              <AutomationToolbar
                automationId={selectedAutomation?.id}
                automationName={selectedAutomation?.name}
                automationCategoryId={selectedAutomation?.automationCategoryId}
                onDelete={handleAutomationDelete}
                handleOpenDetails={handleOpenDetails}
                handleOpenHistory={handleOpenHistory}
                handleDuplicateAutomation={handleDuplicateAutomation}
              />
            </div>
            <div
              className={
                'flex h-full w-full grow gap-2 overflow-scroll bg-gray-100 p-4 duration-300 ease-in-out'
              }
            >
              {(deleteAutomationIsLoading || duplicateAutomationIsLoading) && (
                <div className="flex w-full items-center justify-center">
                  <div className="flex flex-col items-center justify-between gap-4 overflow-hidden p-4 py-6">
                    <Loading />
                    <p className="mt-4 text-lg italic text-gray-500">
                      {duplicateAutomationIsLoading && 'Duplicating Automation'}
                      {deleteAutomationIsLoading && 'Deleting Automation'}
                    </p>
                  </div>
                </div>
              )}

              {!deleteAutomationIsLoading && !duplicateAutomationIsLoading && (
                <div className="flex w-full flex-col">
                  <AutomationTrack
                    automation={selectedAutomation}
                    handleOpenEdit={handleOpenEdit}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <Drawer
          className="relative z-[0] !duration-300 !ease-in-out"
          elevation={0}
          sx={{
            width: !automationsSettingsExpanded.value ? 0 : '600px',
            flexShrink: 0,
            position: 'relative',
            transitionTimingFunction: 'ease-in-out',
            transitionDuration: '300ms',
            maxHeight: '100vh',
            '& .MuiDrawer-paper': {
              width: '600px',
              position: 'absolute',
              flexGrow: 1,
              boxSizing: 'border-box',
              transitionTimingFunction: 'ease-in-out!important',
              transitionDuration: '300ms!important',
            },
          }}
          variant="persistent"
          anchor="right"
          hideBackdrop
          open={automationsSettingsExpanded.value}
        >
          {automationsSettingsExpanded.value && (
            <>
              <div className="sticky top-0 z-[3] bg-white">
                <p className="line-clamp-1 flex h-[75px] w-full items-center justify-between border-b p-4 text-lg capitalize">
                  <span>{settingsSidebarState}</span>
                  <IconButton
                    className={
                      'flex-shrink rounded-lg bg-gray-100 p-2 hover:!bg-gray-200'
                    }
                    onClick={() => {
                      automationsSettingsExpanded.setFalse()
                    }}
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </IconButton>
                </p>
              </div>
              <div className="flex grow">
                {settingsSidebarState === SettingsSidebarState.DETAILS && (
                  <AutomationDetails automationId={selectedAutomation?.id} />
                )}
                {settingsSidebarState === SettingsSidebarState.HISTORY && (
                  <>
                    <AutomationHistory
                      automationId={selectedAutomation?.id}
                      handleViewDetails={handleViewDetails}
                    />
                  </>
                )}
                {settingsSidebarState === SettingsSidebarState.EDIT && (
                  <>
                    {baserowLoading && <AutomationFetchLoading />}
                    {!baserowLoading && (
                      <>
                        {settingsSidebarEditState ===
                          SettingsSidebarEditState.TRIGGER && (
                          <AutomationEditTriggerForm
                            selectedAutomation={selectedAutomation}
                            selectedAutomationBaserowData={
                              selectedAutomationBaserowData
                            }
                            baserowResults={baserowResults}
                          />
                        )}
                        {settingsSidebarEditState ===
                          SettingsSidebarEditState.FILTER && (
                          <AutomationEditFilterForm
                            selectedAutomation={selectedAutomation}
                            selectedAutomationBaserowData={
                              selectedAutomationBaserowData
                            }
                          />
                        )}
                        {settingsSidebarEditState ===
                          SettingsSidebarEditState.ACTION && (
                          <AutomationEditActionForm
                            selectedAutomation={selectedAutomation}
                            selectedAutomationBaserowData={
                              selectedAutomationBaserowData
                            }
                            baserowResults={baserowResults}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </Drawer>
      </div>

      <CreateAutomationModal
        createAutomationInCategoryId={createAutomationInCategoryId}
        openModal={createModalOpen}
        setSelectedAutomation={setSelectedAutomation}
        handleClose={() => {
          setCreateAutomationInCategoryId(null)
          setCreateModalOpen(false)
        }}
      />
      <AutomationHistoryModal
        selectedRecordId={selectedRecordId}
        selectedAutomation={selectedAutomation}
        openModal={historyModalOpen}
        handleModalClose={handleModalClose}
        selectedAutomationBaserowData={selectedAutomationBaserowData}
      />
    </>
  )
}

export default AutomationLayout
