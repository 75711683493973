import React, { FC } from 'react'

import Stack from '@mui/material/Stack'

import RowDetails from 'src/components/Automations/AutomationLogModal/Components/RowDetails'
import SectionTitle from 'src/components/Automations/AutomationLogModal/Components/SectionTitle'
import { AutomationLogActionItemType } from 'src/components/Automations/AutomationLogModal/utils'
import {
  BaserowField,
  EnrichedActionType,
} from 'src/components/Automations/utils'

import ActionItemRow from './ActionItemRow'
import DetailValue from './DetailView'

interface ActionItemProps {
  index: number
  actionData: AutomationLogActionItemType
  baserowTableFields: BaserowField[]
  automationLogRelatedAutomationAction: EnrichedActionType[]
}

const ActionItem: FC<ActionItemProps> = ({
  index,
  actionData,
  baserowTableFields,
  automationLogRelatedAutomationAction,
}) => {
  return (
    <div className={'w-full border-2 p-2'}>
      <Stack direction={'row'} className="justify-between px-2 pb-1">
        <SectionTitle sectionName={`Action No. ${index + 1}`} />

        <RowDetails
          status={actionData.actionSuccess ? 'COMPLETED' : 'FAILED'}
          informationItems={[
            {
              key: 'Row',
              value: actionData?.actionedRowId?.toString() || 'N/A',
            },
            {
              key: 'Table',
              value: actionData?.actionedTableName,
            },
            {
              key: 'Base',
              value: actionData?.actionedBaseId?.toString(),
            },
          ]}
        />
      </Stack>

      <div className="flex flex-wrap gap-6">
        <div className="w-full rounded border bg-gray-50 p-4">
          <Stack direction={'row'} spacing={2} className="mb-2 border-b pb-1">
            <DetailValue setWidth>
              <b>
                {actionData?.actionType === 'SEND_EMAIL'
                  ? 'Recipient'
                  : 'Field'}
              </b>
            </DetailValue>
            <DetailValue setWidth>
              <b>
                {actionData?.actionType === 'SEND_NOTIFICATION'
                  ? 'Details'
                  : 'New Value'}
              </b>
            </DetailValue>
          </Stack>

          {actionData &&
            Object.entries(actionData.actionData).map(
              ([fieldId, fieldValue], index) => (
                <ActionItemRow
                  key={index}
                  fieldIdFull={fieldId}
                  value={fieldValue.truncatedStringValue}
                  baserowTableFields={baserowTableFields}
                  automationLogRelatedAutomationAction={
                    automationLogRelatedAutomationAction
                  }
                />
              ),
            )}
        </div>
      </div>
    </div>
  )
}

export default ActionItem
